.container.content-container {
    height: 85vh;
    max-height: 100vh;
}

td .invalid-feedback {
    width: 100%;
}

.invoice-table {
    border-bottom: 1px solid #dee2e6;
    margin: 1em 0;
    table-layout: fixed;
}

.invoice-table > .table-head > div,
.invoice-table > .table-row > div {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

.invoice-table > .table-head > div {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
    font-weight: bold;
}

.invoice-table > .table-row:nth-of-type(even) {
    background-color: rgba(0, 0, 0, 0.05);
}

.invoice-table input[type=checkbox] {
    margin: 0 20px 0 5px;
    width: 15px;
}

@media only screen and (max-width: 576px) {
    .invoice-table > .table-row > .invoice-location {
        order: 10;
        border-top: 0;
        padding-left: 50px;
        padding-top: 0;
        padding-bottom: 0;
        margin-top: -10px;
    }

    .invoice-table > .table-row > .invoice-payment {
        border-top: 0;
        padding-left: 50px;
    }
}

.payment-amount {
    font-size: 1.75em;
}

.payment-declined-text {
    font-size: 28px;
    margin: 2em 0;
}