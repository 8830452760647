.header {
    margin-top: -20px;
    margin-bottom: 20px;
    border-bottom: 8px solid #314761;
}

.organization-logo {
    max-height: 35px;
    margin-right: 20px;
    margin-top: 10px;
}

.brand-none {
    width: 100%;
    text-align: center;
    margin-right: 0;
}

.brand-name {
    display: inline-block;
    line-height: 24px;
}

.organization-name {
    color: #8f8f8f;
    font-size: .5em;
    text-transform: uppercase;
}

.app-name {
    font-weight: 700;
    color: #314761;
}


@media only screen and (min-width: 576px) {
    .brand-name {
        margin-top: 5px;
    }

    .organization-logo {
        max-height: 60px;
        margin-top: 0;
    }
}