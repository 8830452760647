/* Provide sufficient contrast against white background */
body {
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: Lato, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
}

a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
    background-color: #314761;
    border-color: #314761;
}

.btn-action {
    min-width: 200px;
}

.content-block h3 {
    margin-bottom: 1em;
}

.backdrop {
    width: 100%;
    height: 100%;
    background: white;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0.5;
}

.alert.alert-danger {
    z-index: 2;
}

footer {
    margin-top: 80px;
}
.footer-logo {
    max-height: 45px;
}

@media only screen and (min-width: 576px) {
    .page-top.company-logo {
        float: right;
        max-width: 180px;
    }
}

#checkoutIFrame{
    margin:auto;
    height:550px;
    width: 80%;
    border:none;
}

#transactionCancelBtn{
    width:50%;
    margin-left:25%;
    margin-right:25%;
}